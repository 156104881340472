<template>
  <section id="feed">
    <add-new-feed v-if="isAddNewFeedSidebarActive" :is-add-new-feed-sidebar-active.sync="isAddNewFeedSidebarActive" :items-feed="itemsFeed" @refresh="refresh()"/>
    <b-card title="">
        <b-row v-if="!feedSelected">
        <!-- Search -->
        <b-col
            cols="12"
            class="mt-1 mb-1"
        >
            <b-row class="d-flex align-items-center justify-content-end">
                <b-col cols="4">
                    <b-form-input
                        v-model="searchValue"
                        class="d-inline-block mr-1"
                        placeholder="Pesquise..."
                        @keyup="filtro($event, 'title', filterGlobal)"
                    />
                </b-col>
                <b-col cols="auto" class="mr-1 px-0">
                    <b-button
                    variant="primary"
                    @click="clickFilter = !clickFilter"
                    >
                    <span class="text-nowrap">Filtro</span>
                    </b-button>
                    <div class="select-filter" v-if="clickFilter === true">
                      <b-row v-for="(sec, index) in secretaryOptions" :key="index">
                        <b-col cols="1">
                          <input style="margin-left: 5px;" type="checkbox" v-model="selectedOptions" :value="sec.value" @change="handleCheckboxChange" />
                        </b-col>
                        <b-col cols="1">
                          <p>{{ sec.text }}</p>
                        </b-col>
                      </b-row>
                    </div>
                </b-col>
                <b-col cols="auto" class="mr-1 px-0">
                  <b-button
                      variant="primary"
                      @click="isAddNewFeedSidebarActive = true"
                      v-if="creationFeed()"
                  >
                    <span class="text-nowrap">Adicionar</span>
                  </b-button>
                </b-col>
                <b-col cols="auto" class="mr-1 px-0">
                  <b-button
                  variant="primary"
                  @click="clickOrder = !clickOrder"
                  >
                  <span class="text-nowrap">Ordenar</span>
                  </b-button>
                  <div class="select-order" v-if="clickOrder === true">
                    <b-row>
                      <b-col cols="1">
                        <p @click="sortByField('title')">Ordem alfabética</p>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
            </b-row>
        </b-col>
        </b-row>

        <b-row class="px-1" v-if="!feedSelected">
          <div
            v-for="(item, index) in feed_filtred"
            :key="item.id"
            style="margin: 5px;"
          >
            <a
              v-if="!disabled[index]"
              :style="item.ready === 0 ? `cursor: not-allowed;opacity:1;` : ''"
              @click="goFeed(item.id, item.title, item.secretary, item.banner, item.user_id, item.link, item.created_at)"
            >
              <div class="col card-feed px-0" cols="3" style="margin-right: 4px;">
                <img :src="`${path}/storage/images/feed_banner/${item.banner}`" alt="Image" class="image-feed">
                <h4 class="mb-2" style="padding-left: 3px; padding-right: 3px;">
                {{ item.title }}
                </h4>
              </div>
              </a>
              <a v-else>
                <div class="col" cols="3">
                  <img :src="`${path}/storage/images/feed_banner/${item.banner}`" alt="Image" class="image-feed">
                  <h4 class="mb-2" style="padding-left: 4px; padding-right: 4px;">
                  {{ item.title }}
                  </h4>
                </div>
              </a>
          </div>
        </b-row>
        <b-row class="justify-content-end" v-if="feedSelected">
          <b-col cols="auto" class="mr-1 px-0" v-if="creation()">
            <b-button
              variant="primary"
              @click="isAddNewFeedSidebarActive = true"
            >
              <span class="text-nowrap">Editar</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="justify-content-between pl-1 pt-1 pb-6" v-if="feedSelected" style="margin-bottom: 160px;">
          <b-col cols="4">
            <div class="col card-feed px-0" cols="3" style="margin-right: 4px;">
              <img :src="`${path}/storage/images/feed_banner/${itemSelected[0].banner}`" alt="Image" class="image-feed">
              <h4 class="mb-2" style="padding-left: 3px; padding-right: 3px;">
              {{ itemSelected[0].title }}
              </h4>
            </div>
            <div class="d-flex justify-content-center">
              <a :href="itemSelected[0].link" target="_blank">
                <b-button
                  variant="primary"
                  class="px-5"
                >
                  <span class="text-nowrap">Visitar</span>
                </b-button>
              </a>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="pr-1"  style="border: 1px solid #FFF; border-radius: 5px; padding: 20px;">
              <b-row class="justify-content-center pt-1 pb-3">
                <h4>Informações</h4>
              </b-row>
              <b-row class="justify-content-start align-items-baseline pl-2">
                <h5 style="margin-right: 5px;">Título:</h5>
                <p>{{ itemSelected[0].title }}</p>
              </b-row>
              <b-row class="justify-content-start align-items-baseline pl-2">
                <h5 style="margin-right: 5px;">Secretaria:</h5>
                <p>{{ itemSelected[0].secretary }}</p>
              </b-row>
              <b-row class="justify-content-start align-items-baseline pl-2">
                <h5 style="margin-right: 5px;">Data de criação:</h5>
                <p>{{ itemSelected[0].created_at }}</p>
              </b-row>
              <b-row class="justify-content-start align-items-baseline pl-2">
                <h5 style="margin-right: 5px;">Criado por:</h5>
                <p>{{ itemSelected[0].name_user }}</p>
              </b-row>
              <b-row class="justify-content-start align-items-baseline pl-2">
                <h5 style="margin-right: 5px;">Link:</h5>
                <p>{{ itemSelected[0].link }}</p>
              </b-row>
            </div>
          </b-col>
        </b-row>
    </b-card>
  </section>
</template>

<script>
import FeedServices from './FeedService'
import { BCard, BRow, BCol, BTable, BFormInput, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import AddNewFeed from './AddNewFeed.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    vSelect,
    BFormInput,
    AddNewFeed
  },
  setup() {
    const isAddNewFeedSidebarActive = ref(false)
    return {
      isAddNewFeedSidebarActive,
    }
  },
  data() {
    return {
      nameFilter: '',
      feed: [],
      feed_filtred: [],
      feedSelected: false,
      itemSelected: [],
      itemsFeed: [],
      path: '',
      nameCreation: '',
      disabled: [],
      feedList: [],
      selectedOptions: [],
      clickFilter: false,
      clickOrder: false,
      searchValue: '',
      ready: false,
      secretaryOptions: [
        { value: 'educacao', text: 'Secretaria de Educação' },
        { value: 'saude', text: 'Secretaria de Saúde' },
        { value: 'transporte', text: 'Secretaria de Transporte' },
        { value: 'cultura', text: 'Secretaria de Cultura' },
        { value: 'meio_ambiente', text: 'Secretaria de Meio Ambiente' },
        { value: 'obras_publicas', text: 'Secretaria de Obras Públicas' },
        { value: 'assistencia_social', text: 'Secretaria de Assistência Social' },
        { value: 'financas', text: 'Secretaria de Finanças' },
        { value: 'esporte_lazer', text: 'Secretaria de Esporte e Lazer' },
        { value: 'desenvolvimento_economico', text: 'Secretaria de Desenvolvimento Econômico' },
        { value: 'turismo', text: 'Secretaria de Turismo' },
        { value: 'agricultura', text: 'Secretaria de Agricultura' },
        { value: 'planejamento', text: 'Secretaria de Planejamento' },
        { value: 'comunicacao', text: 'Secretaria de Comunicação' },
        { value: 'seguranca_publica', text: 'Secretaria de Segurança Pública' },
        { value: 'habitacao', text: 'Secretaria de Habitação' },
        { value: 'recursos_humanos', text: 'Secretaria de Recursos Humanos' },
        { value: 'tecnologia_informacao', text: 'Secretaria de Tecnologia da Informação' },
        { value: 'industria_comercio', text: 'Secretaria de Indústria e Comércio' },
      ]
    }
  },
  mounted() {
    this.itemsFeed = [
      {
        'nameSidebar': 'Adicionar'
      }
    ]
    this.path = process.env.VUE_APP_API_BASE
    FeedServices().getFeeds().then(api => {
      this.feed = api.data.feed
      this.feed_filtred = this.orderFeedByDate(this.feed)
    })
    this.feed_filtred = this.orderFeedByDate(this.feed)
    FeedServices().fetchUsers().then(api => {
      this.nameFilter = api.data.response.users;
    })
  },
  methods: {
    sortByField(field) {
      this.feed_filtred.sort((a, b) => {
        const fieldA = a[field].toUpperCase();
        const fieldB = b[field].toUpperCase();
        return fieldA.localeCompare(fieldB);
      });
    },
    orderFeedByDate(arr) {
      return arr.sort((a, b) =>
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        new Date(`${b.date} ${b.time}`) - new Date(`${a.date} ${a.time}`)
      )
    },
    filtro(e, propriedadePesquisa, filterGlobal) {
      if (e.target.value.trim() !== '') {
        const lista = this.feed
        const result = filterGlobal(e, lista, propriedadePesquisa)
        this.feed_filtred = result
      } else {
        this.feed_filtred = this.orderFeedByDate(this.feed)
      }
    },
    handleCheckboxChange() {
      this.feed_filtred = this.feed.filter((item) => {
        return this.selectedOptions.includes(item.secretary);
      });
      if(this.selectedOptions.length === 0) {
        this.feed_filtred = this.feed
      }
    },
    goFeed(id, title, secretary, banner, user_id, link, created_at) {
      this.feedSelected = true;

      let listTime = created_at.split('-');
      let dateCreated = `${listTime[2][0]}${listTime[2][1]}/${listTime[1]}/${listTime[0]}`;

      let categoryFeed = this.secretaryOptions.find(item => item.value === secretary).text;

      this.itemsFeed = [
        {
          'id': id,
          'title': title,
          'secretary': secretary,
          'banner': banner,
          'user_id': user_id,
          'name_user': this.nameCreation,
          'link': link,
          'created_at': dateCreated,
          'nameSidebar': 'Editar'
        }
      ]

      
      this.nameCreation = `${this.nameFilter.find(item => item.id === user_id).name}`

      this.itemSelected = [
        {
          'id': id,
          'title': title,
          'secretary': categoryFeed,
          'banner': banner,
          'user_id': user_id,
          'name_user': this.nameCreation,
          'link': link,
          'created_at': dateCreated,
        }
      ]

    },
    creation() {
      return this.itemSelected[0].user_id === JSON.parse(window.localStorage.getItem('userData')).id && JSON.parse(window.localStorage.getItem('userData')).profession === "jornalista";
    },
    creationFeed() {
      return JSON.parse(window.localStorage.getItem('userData')).profession === "jornalista";
    },
    reloadFeed() {
      window.location.reload();
    }
  }
}
</script>

<style>
  .image-feed {
    width: 314px;
    height: 185px;
    border-radius: 4px;
    border: 1px;
    margin-bottom: 13px;
  }

  .card-feed:hover {
    cursor: pointer;
  }

  .select-filter {
    position: absolute;
    width: 330px;
    z-index: 999;
    border-radius: 5px;
    border: 1px solid #161D31;
    background-color: #283046; 
    margin-top: 10px;
    padding-top: 10px; 
    padding-bottom: 10px; 
    padding-left: 5px; 
    padding-right: 5px;
  }

  .select-filter p {
    width: 300px;
  }

  .select-order {
    position: absolute;
    width: 100px;
    z-index: 999;
    border-radius: 5px;
    border: 1px solid #161D31;
    background-color: #283046; 
    margin-top: 10px;
    padding-top: 10px; 
    padding-bottom: 10px; 
    padding-left: 5px; 
    padding-right: 5px;
  }

  .select-order p {
    width: 80px;
  }

  .select-order p:hover {
    background-color: #161D31;
    border-radius: 5px;
    cursor: pointer;
  }
</style>
